import axios, { Method } from "axios";

interface TExecOption {
  qs?: any;
  timeout?: number;
  headers?: any;
  token?: string;
  data?: { [key: string]: any };
}
export default function() {
  const token = localStorage.getItem("x-token");
  const exec = (method: Method, path?: string, option: TExecOption = {}) => {
    return axios({
      method,
      baseURL: process.env.VUE_APP_BASE_URL,
      url: path || undefined,
      params: option.qs,
      timeout: option.timeout,
      data: option.data,
      headers: {
        "x-token": option.token || token,
        ...option.headers
      }
    })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw err.response.data;
      });
  };
  return { exec };
}
