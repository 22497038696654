import { ref } from "vue";
import useAxios from "./useAxios";
import { GET_ALL } from "@/graphql";
export interface TVariablesGetAll {
  limit?: number;
  offset?: number;
  page?: number;
  order?: any;
  filter?: any;
  search?: string;
}
export interface TPagination {
  limit: number;
  offset: number;
  page: number;
  total: number;
}
export default function<T>(
  endPoint: string,
  fragment: string,
  variables?: TVariablesGetAll
) {
  const { exec } = useAxios();
  const loading = ref<boolean>(true);
  const errors = ref<any>();
  const data = ref<T[]>();
  const pagination = ref<TPagination>();
  const initVariables = variables;

  const _fetchData = (variables: any) => {
    exec("post", "", {
      data: { query: GET_ALL(endPoint, fragment), variables }
    })
      .then(res => {
        data.value = res.data["getAll" + endPoint].data as T[];
        pagination.value = res.data.pagination as TPagination;
      })
      .catch(error => (errors.value = error))
      .finally(() => (loading.value = false));
  };

  const reload = (reloadVariables?: TVariablesGetAll) => {
    const newVariables = { ...initVariables, ...reloadVariables };
    _fetchData(newVariables);
  };

  _fetchData(initVariables);

  return {
    loading,
    errors,
    data,
    pagination,
    reload
  };
}
