import { ref } from "vue";
import useAxios from "./useAxios";
import { GET_ONE } from "@/graphql";
export interface TVariablesGetAll {
  limit?: number;
  offset?: number;
  page?: number;
  order?: any;
  filter?: any;
  search?: string;
}
export interface TPagination {
  limit: number;
  offset: number;
  page: number;
  total: number;
}
export default function<T>(
  endPoint: string,
  fragment: string,
  variables: { id?: string }
) {
  const initVariables = variables;
  const { exec } = useAxios();
  const loading = ref<boolean>(true);
  const errors = ref<any>();
  const data = ref<T>();

  const _fetchData = (variables: any) => {
    exec("post", "", {
      data: { query: GET_ONE(endPoint, fragment), variables }
    })
      .then(res => {
        data.value = res.data["getOne" + endPoint] as T;
        loading.value = false;
      })
      .catch(error => {
        loading.value = false;
        errors.value = error;
      });
  };

  _fetchData(initVariables);

  const reload = (reloadVariables?: { id: string }) => {
    const newVariables = { ...initVariables, ...reloadVariables };
    _fetchData(newVariables);
  };

  return {
    loading,
    errors,
    data,
    reload
  };
}
