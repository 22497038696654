import { ref, watchEffect } from "vue";
export default function() {
  const vh = ref<number>(0);
  const vw = ref<number>(0);
  const handleResize = () => {
    vh.value = window.innerHeight;
    vw.value = window.innerWidth;
  };
  handleResize();
  watchEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const remove = () => {
    window.removeEventListener("resize", handleResize);
  };

  return {
    vh,
    vw,
    remove
  };
}
