export const GET_ALL = (point: string, fragment: string) => `
    query getAll(
        $limit: Int
        $offset: Int
        $page: Int
        $order: Mixed
        $filter: Mixed
        $search: String
        ) {
        getAll${point}(q:{limit:$limit, offset:$offset, page: $page, order:$order, filter: $filter, search:$search}){
            data{${fragment}}
            pagination{
                limit
                offset
                page
                total
            }
        }
    }
`;

export const GET_ONE = (point: string, fragment: string) => `
    query getOne($id: ID!) {
        getOne${point}(id: $id){
            ${fragment}
        }
    }
`;

export const MATERIAL_FRAGMENT = `
    id
    name
    description
    htmlContent
    listImages
    thumbnail
    createdAt
    updatedAt
    regionCount
`;

export const FARMING_TYPE_FRAGMENT = `
    id
    name
    description
    listImages
    createdAt
    updatedAt
    regionCount
`;
export const PHASES_FRAGMENT = `
    id
    name
    status
    startAt
    endAt
    attributes{
        key
        display
        type
        options
        min
        max
        required
        }
    phaseLogIds
    farmingDiaryId
    createdAt
    updatedAt
`;
export const FARMING_DIARY_FRAGMENT = `
    id
    name
    startAt
    endAt
    production
    status
    createdAt
    updatedAt
    material{${MATERIAL_FRAGMENT}}
    phases{${PHASES_FRAGMENT}}
`;

export const DISEASE_FRAGMENT = `
    id
    name
    createdAt
    updatedAt
    description
    htmlContent
    listImages
`;

export const STAFF_FRAGMENT = `
    id
    phone
    email
    name
    avatar
    uid
    isBlock
    address
    province
    district
    ward
    provinceId
    districtId
    wardId
    createdAt
    updatedAt
`;
export const DISEASE_SITUATION_FRAGMENT = `
    id
    createdAt
    updatedAt
    title
    description
    listImages
    reportedAt
    status
    reporterName
    reporterAvatar
    disease{${DISEASE_FRAGMENT}}
    stats
    commentCount
`;

export const REGION_FRAGMENT = `
    id
    name
    province
    district
    ward
    provinceId
    districtId
    wardId
    locationLat
    locationLng
    location
    area
    managerId
    zoneId
    ownerName
    ownerPhone
    ownerAddress
    assigneeIds
    materialId
    materialName
    htmlContent
    farmingTypeId
    listImages
    material{${MATERIAL_FRAGMENT}}
    farmingType{${FARMING_TYPE_FRAGMENT}}
    certificates{title description listImages effectiveDate expiredDate}
    createdAt
    updatedAt
`;

export const DISEADE_COMMENT_FRAGMENT = `
    id       
    createdAt      
    updatedAt      
    message       
    listImages      
    commenterName
    commenterAvatar           
    situation {${DISEASE_SITUATION_FRAGMENT}}         
`;

export const PHASELOG_FRAGMENT = `
    id
    name
    attributes{display key value}
    time
    phaseId
    staffId
    createdAt
    updatedAt
`;
