import { useRouter } from "vue-router";

export default function() {
  const { currentRoute, push, replace, back } = useRouter();
  const { params, path, query } = currentRoute.value;
  return {
    query: { ...query, ...params },
    name,
    path,
    push,
    replace,
    back
  };
}
